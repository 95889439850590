<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoice === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-row
      v-if="invoice"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="invoice"
          no-body
          class="invoice-preview-card"
        >
          <div
            class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
          >
            <div style="color: black">
              <div class="logo-wrapper">
                <img
                  src="@/assets/images/logo/logo.png"
                  width="250px"
                  height="100%"
                >
                <h3 class="text-primary invoice-logo" />
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="ml-3">
              <h1 style="color: black; font-size: 30px">
                Règlement N°: {{ invoice.id }}
              </h1>
            </div>
            <div>
              <h3
                class="mr-3"
                style="color: black"
              >
                Date:
                <span style="color: black"> {{ formatFn(invoice.created_at) }}</span>
              </h3>
            </div>
          </div>
          <b-card-body class="d-flex flex-row justify-content-between">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="ml-2"
                style="border: 2px solid black"
              >
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>{{ company.name }} :</strong> Société de livraison des colis
                </b-card-text>
                <b-card-text
                  class="ml-2 h3 mr-2 mb-1"
                  style="color:black"
                >
                  <strong>Matricule fiscale :</strong> {{ company.tax_identification_number }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Adresse :</strong> {{ company.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Tel :</strong> {{ company.phone1 }}
                </b-card-text>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="ml-2"
                style="border: 2px solid black"
              >
                <b-card-text
                  v-if="invoice.supplier"
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <strong> Fournisseur : </strong> {{ invoice.supplier.username }}
                </b-card-text>
                <b-card-text
                  class="ml-2 h3 mr-2 mb-1"
                  style="color:black"
                >
                  <strong> Matricule fiscale : </strong> {{ invoice.supplier.tax_identification_number }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong> Adresse : </strong> {{ invoice.supplier.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong> Tel : </strong> {{ invoice.supplier.phone }}
                </b-card-text>
              </div>
            </div>

          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body
            class=" pb-10 mt-1 d-flex justify-content-between"
            style=""
          >
            <!-- Col: Total -->

            <table style="border: 2px solid black">
              <tr>
                <th>Nombre de colis</th>
                <th>Colis livré</th>
                <th>Colis retour</th>
                <th>Total COD</th>
                <th>Frais de livraison</th>
                <th>Net a payer</th>
              </tr>
              <tr>
                <td style="font-size:20px">
                  {{ invoice.total_nb }}
                </td>
                <td style="font-size:20px">
                  {{ invoice.nb_delivered }}
                </td>
                <td style="font-size:20px">
                  {{ invoice.nb_return }}
                </td>
                <td style="font-size:20px">
                  {{ invoice.total_ttc }}
                </td>
                <td style="font-size:20px">
                  {{ invoice.total_delivery }}
                </td>
                <td style="font-size:20px">
                  {{ invoice.total }}
                </td>
              </tr>
            </table>

          </b-card-body>
          <b-card-body
            class="invoice-padding pt-0"
          >
            <p
              class="
            font-weight-bold
            text-dark
            h3
            mb-1"
            >
              <feather-icon
                class="mr-1"
                icon="FolderPlusIcon"
                style="width: 24px;height: 24px;"
                color="#208a9d"
              />
              <span style="color:#208a9d">Colis livré</span>
            </p>
            <b-table-lite
              responsive
              class="ta"
              :items="delivredOrder"
              :fields="['code','destinataire','gouvernorat','tel','prix','prixLivraison']"
            >
              <template
                #cell(code)="data"
              >
                <h5>{{ data.item.code }}</h5>
                <span
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <b-card-body
            v-if="retour"
            class="invoice-padding pt-0"
          >
            <p class="font-weight-bold text-dark h3 mb-1">
              <feather-icon
                icon="FolderMinusIcon"
                style="width: 24px;height: 24px;"
                class="mr-1"
                color="red"
              />
              <span style="color:red">Colis retour</span>
            </p>
            <b-table-lite
              class="ta"
              responsive
              :items="retour"
              :fields="['code','destinataire','gouvernorat','tel','prixRetour']"
            >
              <template
                #cell(code)="data"
              >
                <h5>{{ data.item.code }}</h5>
                <span
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <!-- Invoice Description: Table -->

          <!-- Invoice Description: Total -->

          <b-card-body class="d-flex justify-content-between mt-3">
            <!-- Col: Total -->

            <div class="mb-5 ml-1">
              <h3 style="color: black; font-size: 25px">
                Signature
              </h3>
            </div>
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :class="{ 'disabled-cursor': isLoading }"
            :disabled="isLoading"
            @click="printInvoice"
          >
            Imprimer
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Client',
          formatter: customer => `${customer.company_name}`,
        },
        {
          key: 'customer.address',
          label: 'address',
        },
        {
          key: 'customer.phone',
          label: 'téléphone',
        },
        {
          key: 'customer.tax_identification_number',
          label: 'MF',
        },
      ],
      p: [],
      test: '0',
      invoice: [],
      delivredOrder: [],
      retour: [],
      company: {},
      isLoading: false,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.company = storeAuth.state.company
    this.getInvoice()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getInvoice() {
      this.isLoading = true
      await axios
        .get(`/api/invoices/detail/${this.$route.params.id}`)
        .then(response => {
          this.invoice = response.data
          const { delivred_orders } = this.invoice

          for (let i = 0; i < delivred_orders.length; i += 1) {
            const data = {
              code: delivred_orders[i].id,
              gouvernorat: delivred_orders[i].governorate,
              tel: delivred_orders[i].phone1,
              destinataire: delivred_orders[i].name,
              adresse: delivred_orders[i].address,
              prix: delivred_orders[i].price_ttc,
              prixLivraison: delivred_orders[i].price_delivery,
              status: 'livré',
              is_an_exchange: delivred_orders[i].is_an_exchange,

            }
            this.delivredOrder.push(data)
          }
          const { return_orders } = this.invoice

          for (let i = 0; i < return_orders.length; i += 1) {
            const data = {
              code: return_orders[i].id,
              gouvernorat: return_orders[i].governorate,
              tel: return_orders[i].phone1,
              destinataire: return_orders[i].name,
              adresse: return_orders[i].address,
              prix: return_orders[i].price_ttc,
              prixRetour: return_orders[i].price_return,
              status: 'retour',
              is_an_exchange: return_orders[i].is_an_exchange,
            }
            this.retour.push(data)
          }
        })
      this.isLoading = false
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeFrais = 0
    const total = 0
    const tax = 0
    const sommeHT = 0
    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
      sommeHT,
      tax,
      total,
      sommeFrais,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #208a9d;
}
.contact {
  background-color: #208a9d;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  .ta {
    overflow-x: visible;
    font-size: 20px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #208a9d;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #208a9d;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
